import React from "react";
import { Link } from "gatsby";
import ArrowLeftIcon from "../images/svg/arrow-left.svg";

import "../styles/_main.scss";

const Success = () => {
  return (
    <div className="success">
      <div className="success-container">
        <h3>Thank you!</h3>
        <p>Your form submission has been received.</p>
        <Link to="/" className="link">
          <ArrowLeftIcon /> Back to our site
        </Link>
      </div>
    </div>
  );
};

export default Success;
